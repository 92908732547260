import React from 'react';
import s from './Description.module.scss'
import SimpleSlider from "../../slider/Slider";

const Description = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.descriptionWrapper}>
                <div className={s.title}>О нас</div>
                <div className={s.description}>ООО «Альтаир Холод» образовано 19 июля 2012 года. Регистрационный номер
                    191786256.

                    Основной сферой деятельности является изготовление и монтаж изделий из нержавеющей стали, а так же
                    монтаж холодильных камер и сопутствующие этому работы.
                    Возможно так же изготовление и механическая обработка специфических узлов креплений и изделий по
                    индивидуальному заказу.

                    Основными нашими партнёрами и заказчиками являются в основном торговые объекты, административные
                    помещения и здания, владельцы домов частного сектора. Высококачественный инструмент и опытные
                    специалисты позволяют качественно и в срок выполнять заданный объём работы.
                </div>
            </div>
            <div>
                <SimpleSlider/>
            </div>
        </div>
    );
};

export default Description;