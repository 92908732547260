import React from 'react';
import s from './MainFooter.module.scss'

const MainFooter = () => {
    return (
        <div className={s.footerWrapper}>
            <div>
                <div className={s.yearsWrapper}>
                    <div className={s.years}>10</div>
                    <div className={s.svg}>
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                             viewBox="0 0 297 297" width={'70px'}
                             fill={'white'}>
                            <g>
                                <path d="M108.388,297c-2.616,0.002-5.126-1.038-6.977-2.888c-1.849-1.851-2.889-4.359-2.889-6.977l0.014-88.653l-88.621,0.012
		c-2.616,0-5.125-1.038-6.976-2.888c-1.851-1.85-2.889-4.358-2.889-6.977l0.014-80.222c0.001-5.445,4.415-9.86,9.861-9.86
		l88.626-0.014l0.016-88.662c0.001-5.445,4.415-9.858,9.86-9.86L188.616,0c2.617,0,5.126,1.038,6.977,2.888
		c1.85,1.851,2.888,4.359,2.888,6.977l-0.013,88.655l88.617-0.012c2.616,0,5.125,1.038,6.976,2.887
		c1.851,1.852,2.889,4.359,2.889,6.976l-0.012,80.22c-0.001,5.445-4.415,9.86-9.86,9.86l-88.629,0.016l-0.014,88.658
		c-0.001,5.445-4.415,9.86-9.86,9.86L108.388,297z M108.399,178.756c2.616,0,5.125,1.038,6.976,2.888
		c1.85,1.85,2.889,4.359,2.889,6.977l-0.014,88.653l60.462-0.012l0.014-88.659c0-5.445,4.414-9.86,9.86-9.86l88.627-0.016
		l0.009-60.494l-88.618,0.014c-2.615,0-5.124-1.04-6.975-2.89c-1.851-1.849-2.889-4.359-2.889-6.975l0.014-88.655l-60.464,0.008
		l-0.016,88.664c0,5.446-4.414,9.859-9.861,9.861l-88.625,0.012l-0.01,60.498L108.399,178.756z"/>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                        </svg>
                    </div>
                    <div className={s.yearsMarket}>Лет<br/> на рынке</div>
                </div>
            </div>
            <div className={s.yearsWrapper}>
                <div className={s.years}>100</div>
                <div className={s.svg}>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         viewBox="0 0 297 297" width={'70px'}
                         fill={'white'}>
                        <g>
                            <path d="M108.388,297c-2.616,0.002-5.126-1.038-6.977-2.888c-1.849-1.851-2.889-4.359-2.889-6.977l0.014-88.653l-88.621,0.012
		c-2.616,0-5.125-1.038-6.976-2.888c-1.851-1.85-2.889-4.358-2.889-6.977l0.014-80.222c0.001-5.445,4.415-9.86,9.861-9.86
		l88.626-0.014l0.016-88.662c0.001-5.445,4.415-9.858,9.86-9.86L188.616,0c2.617,0,5.126,1.038,6.977,2.888
		c1.85,1.851,2.888,4.359,2.888,6.977l-0.013,88.655l88.617-0.012c2.616,0,5.125,1.038,6.976,2.887
		c1.851,1.852,2.889,4.359,2.889,6.976l-0.012,80.22c-0.001,5.445-4.415,9.86-9.86,9.86l-88.629,0.016l-0.014,88.658
		c-0.001,5.445-4.415,9.86-9.86,9.86L108.388,297z M108.399,178.756c2.616,0,5.125,1.038,6.976,2.888
		c1.85,1.85,2.889,4.359,2.889,6.977l-0.014,88.653l60.462-0.012l0.014-88.659c0-5.445,4.414-9.86,9.86-9.86l88.627-0.016
		l0.009-60.494l-88.618,0.014c-2.615,0-5.124-1.04-6.975-2.89c-1.851-1.849-2.889-4.359-2.889-6.975l0.014-88.655l-60.464,0.008
		l-0.016,88.664c0,5.446-4.414,9.859-9.861,9.861l-88.625,0.012l-0.01,60.498L108.399,178.756z"/>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                    </svg>
                </div>
                <div className={s.yearsMarket}>Завершенных<br/> проектов</div>
            </div>


        </div>
    );
};

export default MainFooter;